import { FetchCallbacks } from "./view";
import { Contact } from "../service/contact";

const URL = "/api/contacts";

export function fetch(callbacks: FetchCallbacks<Array<Contact>>) {
  if (!process.env.BROWSER) {
    callbacks.onError(new Error("Not in a web browser"));

    return;
  }

  callbacks.onLoading();

  window
    .fetch(URL)
    .then(res => res.json())
    .then(json => callbacks.onCompleted(json))
    .catch(e => callbacks.onError(e));
}
