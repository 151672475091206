export interface Source {
  type: string;
  url: string;
  timeout: number;
}

export interface FetchConfig {
  max: number;
  sources: Array<Source>;
}

export enum AttachmentType {
  Image
}

export interface Attachment {
  type: AttachmentType;
  url: string;
}

export interface Feed {
  prefix: string;
  title: string;
  link: string;
  date: number;
  attachments: Array<Attachment>;
}

export interface Day {
  month: number;
  day: number;
  feeds: Array<Feed>;
}

export interface Year {
  year: number;
  days: Array<Day>;
}

export type Feeds = Array<Year>;

export function buildFeeds(w: Array<Feed>, max: number): Feeds {
  const searchDay = (w: Year, month: number, day: number): number => {
    for (let i = 0; i < w.days.length; i++) {
      if (w.days[i].month !== month) {
        continue;
      }

      if (w.days[i].day !== day) {
        continue;
      }

      return i;
    }

    return -1;
  };

  const searchYear = (w: Feeds, y: number): number => {
    for (let i = 0; i < w.length; i++) {
      if (w[i].year !== y) {
        continue;
      }

      return i;
    }

    return -1;
  };

  const insertMonthDay = (dd: Year, month: number, day: number, d: Feed) => {
    const cDay = searchDay(dd, month, day);

    if (cDay >= 0) {
      dd.days[cDay].feeds.push(d);

      return;
    }

    dd.days.push({
      month: month,
      day: day,
      feeds: []
    });

    dd.days[dd.days.length - 1].feeds.push(d);
  };

  const insertYearMonthDay = (
    dd: Feeds,
    year: number,
    month: number,
    day: number,
    d: Feed
  ) => {
    const cYear = searchYear(dd, year);

    if (cYear >= 0) {
      insertMonthDay(dd[cYear], month, day, d);

      return;
    }

    dd.push({
      year: year,
      days: []
    });

    insertMonthDay(dd[dd.length - 1], month, day, d);
  };

  let fs: Feeds = [];

  w.sort((a, b) => b.date - a.date);

  if (w.length > max) {
    w = w.slice(0, max);
  }

  for (let i in w) {
    const date = new Date(w[i].date),
      year = date.getUTCFullYear(),
      month = date.getUTCMonth(),
      day = date.getUTCDate();

    insertYearMonthDay(fs, year, month, day, w[i]);
  }

  return fs;
}
