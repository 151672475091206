export interface Action {
  readonly type: string;
  readonly payload: any;
}

export interface FetchCallbacks<T> {
  onLoading(): void;
  onCompleted(data: T): void;
  onError(e: Error): void;
}

export enum FetchStatus {
  Fetched,
  Fetching,
  Failed
}
