import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./common.css";
import "./page.css";
import "./home.css";
import "./contact.css";
import "./project.css";

import { Application } from "./widgets/Application";
import { Context, Data } from "./widgets/Context";

ReactDOM.hydrate(
  <BrowserRouter>
    <Context.Provider value={Data}>
      <Application initialState={(window as any).__PRELOADED_STATE__} />
    </Context.Provider>
  </BrowserRouter>,
  document.getElementById("app")
);
