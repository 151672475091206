import { FetchCallbacks } from "./view";
import { Feeds } from "../service/feed";

const URL = "/api/feeds";

export function fetch(callbacks: FetchCallbacks<Feeds>) {
  if (!process.env.BROWSER) {
    callbacks.onError(new Error("Not in a web browser"));

    return;
  }

  callbacks.onLoading();

  window
    .fetch(URL)
    .then(res => res.json())
    .then(json => callbacks.onCompleted(json))
    .catch(e => callbacks.onError(e));
}
